// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService, UserModel1 } from "src/app/modules/auth";
import { AuthModel1 } from "src/app/modules/auth/models/auth.model";
declare var require: any;
const pack = require('src/app/modules/auth/models/auth.model');
// @Injectable()
// export class EnvironmentTest{
//   constructor(private httpClient: HttpClient, private authService: AuthService) {
//     let a =this.authService?.USERDATA_KEY
//     alert(a)
//   }
// }



export const environment = {
  
   //authService: AuthService,
  production: false,
  GMapsKey:'AIzaSyCjirUlgby1lfV8BxagtICEBWxlsk1RZlY',
  appVersion: 'v8.2.4',
  USERDATA_KEY:localStorage.getItem('v8.2.4-authData'),
  
 uploadUrl: 'https://upload.finobooks.com/api',

  apiLink: 'https://api.finobooks.com/',
  apiUrl: 'https://api.finobooks.com/api',

  primeUrl: 'https://prime.finobooks.com/api',
  primeLink: 'https://prime.finobooks.com/',

  reportingTool: 'https://print.finobooks.com',
  
 
 
  appDemos: {
    demo1: {
      title: 'Demo 1',
      description: 'Default Dashboard',
      published: true,
      thumbnail: './assets/media/demos/demo1.png',
    },

    
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
