import { environment } from "src/environments/environment";


export class Global {
    public static Company_Branch_EndPoint = `${environment.apiUrl}/CompanyBranch/`;
    public static GMapsKey = environment.GMapsKey;
    public static ApiLink = environment.apiLink;
    public static MainMenu_EndPoint = `${environment.primeUrl}/MainMenu/`;
    public static SubMenu_EndPoint = `${environment.primeUrl}/SubMenu/`;
    public static MenuGroup_EndPoint = `${environment.primeUrl}/MenuGroup/`;
    public static Component_EndPoint = `${environment.primeUrl}/Component/`;
    public static Modules_EndPoint = `${environment.primeUrl}/Modules/`;
    public static ModuleType_EndPoint = `${environment.primeUrl}/ModuleType/`;
    public static Feature_EndPoint = `${environment.primeUrl}/Feature/`;
    public static FeatureType_EndPoint = `${environment.primeUrl}/FeatureType/`;
    public static AddOnModule_EndPoint = `${environment.primeUrl}/AddOnModule/`;
    public static AddOnModuleType_EndPoint = `${environment.primeUrl}/AddOnModuleType/`;
    public static MasterUserActivity_EndPoint = `${environment.primeUrl}/MasterUserActivity/`;
    public static Auth_EndPoint = `${environment.primeUrl}/Auth/`;
    public static MasterUserCompanyRegister_EndPoint = `${environment.primeUrl}/MasterUserCompanyRegister/`;
    public static DataBaseCreation_EndPoint = `${environment.primeUrl}/DataBaseCreation`;
    public static DataBaseConnection_EndPoint = `${environment.primeUrl}/DataBaseConnection`;
    public static Menu_EndPoint = `${environment.primeUrl}/WebMenu/`;
    public static Reporting_Print_EndPoint = '/DXXRD/print';
    public static Lookup_EndPoint = `${environment.primeUrl}/Lookup/`;
   
    //public static GetProfile_EndPoint = `${environment.primeUrl}/GetProfile/`;
    
    
    public static CommonAPIn_EndPoint = `${environment.primeUrl}/CommonAPI/`;
    public static MasterUserSubscription_EndPoint = `${environment.primeUrl}/MasterUserSubscription/`;
    
    
    
    
    
}