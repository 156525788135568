import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tokenexpiredialog',
  templateUrl: './tokenexpiredialog.component.html',
  styleUrl: './tokenexpiredialog.component.scss'
})
export class TokenexpiredialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TokenexpiredialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

ngOnInit():void{
  let that=this;
  Swal.fire({
    title: "Your session will expire in"+ this.decrement(this.data.countdown)  +"seconds.",
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Continue!",
    cancelButtonColor:"#d33",
    cancelButtonText:"Exit",
    showCancelButton: true
  })
.then(function(res) {
  debugger
  if (res.isConfirmed) {
   that.onContinue()
  }
  if (res.isDismissed) {
    debugger
    that.onExit()
}
 })
}

decrement(data:number)
{
  return data--;

}

  onExit(): void {
    this.dialogRef.close('exit');
   
  }
  onContinue(): void {
    this.dialogRef.close('continue');
    
  }
}
