import { AuthModel, AuthModel1 } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  id: number;
  userName: string;
  password: string;
  userPassword:string;
  fullname: string;
  email: string;
  cid:number;
  cbid:number;
  defaultCompany:number;
  selectedCompany:number;
  selectedCompanyKey: string;
  mid:string;
  midnames:string;
  companies:MstCompany[];
  pic: string;
  roles: number[] = [];
  occupation: string;
  companyImage:string;
  companyName: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean;
    sms: boolean;
    phone: boolean;
    
  };
  otp:string;
    userTime:string;
  // email settings
  emailSettings?: {
    emailNotification: boolean;
    sendCopyToPersonalEmail: boolean;
    activityRelatesEmail: {
      youHaveNewNotifications: boolean;
      youAreSentADirectMessage: boolean;
      someoneAddsYouAsAsAConnection: boolean;
      uponNewOrder: boolean;
      newMembershipApproval: boolean;
      memberRegistration: boolean;
    };
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
      tipsOnGettingMoreOutOfKeen: boolean;
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
      tipsOnMetronicBusinessProducts: boolean;
    };
  };

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.userName = user.userName || '';
    this.password = user.password || '';
    this.fullname = user.fullname || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/avatars/blank.png';
    this.roles = user.roles || [];
    this.occupation = user.occupation || '';
    this.companyName = user.companyName || '';
    this.phone = user.phone || '';
    this.address = user.address;
    this.socialNetworks = user.socialNetworks;
  }
}
export class UserModel1  {
 token:string;
 passkey:string;
 success:string
  dev:Dev;
  
}
export class Dev {
  devID:string;
  approvalMember:string;
approvalStatus:boolean;
companyID:number;
date:string;
defaultCompanyName:string;
define:string;
description:string;
masterID:string;
modifiedDate:string;
moduleMembers: []
registerEmailID:string;
registerMobileNo:string;
sno:number;
subscriberStatus:string;
userLicenseKey:string;
userName:string;
validDays:number;
}

export class UserModel3 {
  token: string
  expiresin: string
  passkey: string
  userDtails: UserDtails
}

export class UserDtails {
  userId: string
  userName: string
  emailID: string
  password: string
  mobileNo: string
  normalizedUserName: string
  normalizedEmail: string
  emailConfirmed: boolean
  passwordHash: string
  securityStamp: string
  concurrencyStamp: string
  phoneNumberConfirmed: boolean
  twoFactorEnabled: boolean
  lockoutEnd: string
  lockoutEnabled: boolean
  accessFailedCount: number
  insertedDate: string
  modifiedDate: string
}

export class UserModel2 {
  token: string
  expiresin: string
  passkey: string
  prime: Prime
}

export class Prime {
  primeAdminId: string
  userName: string
  emailID: string
  password: string
  mobileNo: string
  normalizedUserName: string
  normalizedEmail: string
  emailConfirmed: boolean
  passwordHash: string
  securityStamp: string
  concurrencyStamp: string
  phoneNumberConfirmed: boolean
  twoFactorEnabled: boolean
  lockoutEnd: string
  lockoutEnabled: boolean
  accessFailedCount: number
  insertedDate: string
  modifiedDate: string
}

export class MstCompany {
  // AccountType: string;
  // CompanyDescription: string;
  // CompanyStatus: string;
  // companyName: string;
  // CompanyKey: string;
  // cid: number;
  // Mid: string;
  // insertedDate: string;
  // companyImagePath: string
  // companyIcon: string
  // modules: Module[]
  companyID: number
  sno: number
  date: any
  modifiedDate: any
  masterID: string
  username: string
  companyType: any
  companyName: string
  companyPrimaryAddressID: any
  companyBillingAddressID: any
  companyCountry: string
  companyTaxNo: string
  comapnyKey: string
  companyTaxDoc: string
  companyTransactionVoucherPrefix: string
  define: any
  descritpion: any
  databaseCreate: boolean
  adminSubscribers: any
  businessInfo: any
  bankInfo: any
  financialPeriod: any
  taxInfo: any
  emailSettings: any
  onlineSubScriberSettings: any
  webPolicys: any
  googleCredentials: any
  paymentGateways: any
}

export class Module {
  id: number;
  moduleName: string;
  moduleKey: string;
  mid: string;
  cid: number;
}

export class DatabaseConnection {
  DatabaseName: string
  DataSource: string
  AuthoriazationType: string
  UserId: string
  DBPassword: string
  DevPassUIDKey: string
  PrimePassUIDKey: string
  UserPassUIDKey: string
}

export class ProfileData {
  userProfile: UserProfile
  companyInfo: CompanyInfo[]
  subscriberModules: SubscriberModule[]
}

export class UserProfile {
  ID: string
  userName: string
  normalizedUserName: string
  emailID: string
  password: string
  normalizedEmail: string
  emailConfirmed: boolean
  passwordHash: string
  securityStamp: string
  concurrencyStamp: string
  mobileNo: string
  phoneNumberConfirmed: boolean
  twoFactorEnabled: boolean
  lockoutEnd: string
  lockoutEnabled: boolean
  accessFailedCount: number
  insertedDate: string
  modifiedDate: string
}




export class CompanyInfo {
  companyID: number
  sno: number
  date: string
  modifiedDate: string
  masterID: string
  username: string
  companyType: string
  companyName: string
  companyPrimaryAddressID: number
  companyBillingAddressID: string
  companyCountry: string
  companyTaxNo: string
  comapnyKey: string
  companyTaxDoc: string
  companyTransactionVoucherPrefix: string
  define: string
  descritpion: string
  databaseCreate: boolean
  adminSubscribers: any
  businessInfo: any
  bankInfo: any
  financialPeriod: any
  taxInfo: any
  emailSettings: any
  onlineSubScriberSettings: any
  webPolicys: any
  googleCredentials: any
  paymentGateways: any
}

export class SubscriberModule {
  moduleID: string
  id: number
  sno: number
  moduleTypeID: number
  date: string
  modifiedDate: string
  masterID: string
  companyID: string
  features: any
  addOnModules: any
  moduleMembers: any
  menuGroup: any
  mainMenu: any
  subMenu: any
  component: any
  moduleName: string
  typeName: string
  moduleKey: string
  moduleStatus: string
  versionControl: string
  dataSource: string
  solutionControl: string
  define: string
  description: string
  moduleContent: string
  moduleStory: string
  userStory: any
  modulePrice: number
  modulePriceType: boolean
}
