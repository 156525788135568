import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TokenexpiredialogComponent } from '../components/tokenexpiredialog/tokenexpiredialog.component';
import { AuthService } from '..';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {
  private checkInterval: any;
  private warningShown = false;
  private countdownValue = 0;
  tokenExpiration:string;
  timeLeft:number=0;
  ischeck:boolean=false;
  constructor(private router: Router, public dialog: MatDialog,
    private authService:AuthService,
    ) {}
  // Call this function on app init
  startTokenTimer() {
    debugger
    let tokenExpiration:any = localStorage.getItem('token_expiration') ;
  
    let d = tokenExpiration
    let yes:Date= new Date(d)
    yes.setDate(yes.getDate() + 1)

    if (tokenExpiration) {
     // const expirationDate = new Date((yes)).getTime() 3600000;
       const currentTime = new Date().getTime();
      //  60000
       const expirationDate= currentTime + 3600000 ;
  
      const timeLeft = expirationDate - currentTime;
      if (timeLeft > 0) {
        this.checkInterval = setInterval(() => {
         //this.checkTokenExpiry(expirationDate);
          //this.checkTokenExpiry(60000);
        }, 1000); // Check every second
      }
    }
    
   // this.checkTokenExpiry(40000);
  }
  // Check token expiry every second
  // checkTokenExpiry(expirationTime: number) {
  //   debugger
  //   const currentTime = new Date().getTime();
    
  //   this.timeLeft = expirationTime - currentTime;
  //   if (this.timeLeft <= 10000 && !this.warningShown) {  // Trigger warning 5 seconds before expiry
  //     this.warningShown = true;
  //     this.countdownValue = Math.floor(this.timeLeft / 1000);
  //     this.showExpirationWarning();
      
  //   }
  //   if (this.timeLeft <= 0 && this.ischeck==false) {
  //     clearInterval(this.checkInterval);
  //     this.logout(); // Token expired
  //   }
  // }
  // Show the warning pop-up
  showExpirationWarning() {
    debugger
    const dialogRef = this.dialog.open(TokenexpiredialogComponent, {
      width: '300px',
      data: { countdown: this.countdownValue }
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result === 'continue') {
        // if (result) {
          this.ischeck = true;
        this.refreshToken(); // Call API to refresh token
       // this.cdr.detectChanges();
      } else {
        this.logout(); // User opted to exit
      //  this.cdr.detectChanges();
      }
    });
  }
  // Simulate token refresh
  refreshToken() {
    debugger
    // Refresh the token and reset the timer here
   // const newExpiration = new Date().getTime() + 60000;  // Example: 1 more minute
   const newExpiration =Number(new Date().getTime()) + Number(3600000) ;
    localStorage.setItem('token_expiration', newExpiration.toString());
    this.startTokenTimer();  // Restart the timer
  }
  logout() {
    debugger
    //localStorage.clear(); // Clear token and related data
    // this.router.navigate(['/login']); // Redirect to login
   localStorage.removeItem('token');
   localStorage.removeItem('token_expiration');
     localStorage.removeItem('v8.2.4-authData');
     localStorage.removeItem('tabData');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
    document.location.reload()
  }
}