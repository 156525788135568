import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProfileData, UserModel, UserModel1, UserModel2, UserModel3 } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel, AuthModel1, otpMail, validateCredentials } from '../../models/auth.model';
import { formatDate } from '@angular/common';
import { UserType } from '../auth.service';

const API_USERS_URL = `${environment.primeUrl}/Auth`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*'
  })
};

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  // login(email: string, password: string,otp:string): Observable<any> {
  //   const userTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
  //   console.log("user login time", userTime)
  //   return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {
  //     email,
  //     password,
  //     otp,
  //     userTime
  //   });
  // }
  
  login(Username: string, Password: string): Observable<any> {
    const LoginDateTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
   debugger
    console.log("user login time", LoginDateTime)
    return this.http.post<AuthModel1>(`${API_USERS_URL}/Login`, {
      Username,
      Password,
      LoginDateTime
    });
  }

  primeLogin(Username: string, Password: string): Observable<any> {
    const LoginDateTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
   
    console.log("user login time", LoginDateTime)
    return this.http.post<AuthModel1>(`${API_USERS_URL}/PrimeLogin`, {
      Username,
      Password,
      LoginDateTime
    });
  }

  adminLogin(Username: string, Password: string): Observable<any> {
    const LoginDateTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
   
    console.log("user login time", LoginDateTime)
    return this.http.post<AuthModel1>(`${API_USERS_URL}/AdminLogin`, {
      Username,
      Password,
      LoginDateTime
    });
  }

  getCode(devId: string): Observable<any> {
    return this.http.get<any>(`${environment.primeUrl}/Authenticator/GetCode/`+devId);
  }

  otpMail(EmailToId: string): Observable<any> {
    const EmailTime = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.SSS', 'en-US');
    console.log("otp send time", EmailTime)
    return this.http.post<otpMail>(`${environment.apiUrl}/Mail/SendOtp`, {
      EmailToId,
      EmailTime
    });
  }

  verifyOtp(EmailId: string,EmailTime:string,OTP:string): Observable<any> {
    return this.http.post<otpMail>(`${environment.apiUrl}/Mail/Verify`, {
      EmailId,
      EmailTime,
      OTP
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(Username: string,NewPassword:string): Observable<boolean> {
    debugger
    return this.http.put<boolean>(`${API_USERS_URL}/Update`, {
      Username,
      NewPassword
    });
  }

  // getUserByToken(token: string): Observable<any> {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `Bearer ${token}`,
  //   });
  //   debugger
  //   return this.http.get<any>(`${API_USERS_URL}/me`, {
  //     headers: httpHeaders,
  //   });
  // }

  // getUserByToken(token: string): Observable<UserModel> {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `Bearer ${token}`,
      
  //   });
    
  //   debugger
  //   return this.http.get<UserModel>(`${API_USERS_URL}/me`, {
  //     headers: httpHeaders,
  //   });
  // }


  getUserByToken(token:string,masterID: string,passkey:any): Observable<UserModel1> {
 
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<UserModel1>(`${environment.primeUrl}/GetAuthorize/Get`, {
      masterID,passkey
    },{headers: httpHeaders});
  }

  getUserByTokenPrime(token:string,masterID: string,passkey:any): Observable<UserModel2> {
   
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<UserModel2>(`${environment.primeUrl}/GetAuthorize/Get`, {
      masterID,passkey
    },{headers: httpHeaders});
  }

  getUserByTokenSubscribe(token:string,masterID: string,passkey:any): Observable<UserModel3> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<UserModel3>(`${environment.primeUrl}/GetAuthorize/Get`, {
      masterID,passkey
    },{headers: httpHeaders});
  }

  getDevProfileInfo(MasterID: string,passkey:string,token:string): Observable<ProfileData> {
    debugger
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<ProfileData>(`${environment.primeUrl}/GetProfile/Developer`, {
      MasterID,passkey  
    },{headers: httpHeaders});
  }

  getPrimeProfileInfo(MasterID: string,passkey:string,token:string): Observable<ProfileData> {
    debugger
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<ProfileData>(`${environment.primeUrl}/GetProfile/Prime`, {
      MasterID,passkey  
    },{headers: httpHeaders});
  }

  getSubscribeProfileInfo(MasterID: string,passkey:string,token:string): Observable<ProfileData> {
    debugger
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<ProfileData>(`${environment.primeUrl}/GetProfile/AdminSubscribe`, {
      MasterID,passkey  
    },{headers: httpHeaders});
  }

  validateEmailCredentials(EmailID: string): Observable<any> {
    return this.http.post<validateCredentials>(`${environment.primeUrl}/MasterValidation/EmailID`, {
      EmailID,  
    });
  }

  validateMobileCredentials(MobileNo: string): Observable<any> {
    return this.http.post<validateCredentials>(`${environment.primeUrl}/MasterValidation/MobileNo`, {
      MobileNo,  
    });
  }

  validateUserNameCredentials(Username: string): Observable<any> {
    return this.http.post<validateCredentials>(`${environment.primeUrl}/MasterValidation/Username`, {
      Username,  
    });
  }

}
