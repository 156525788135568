import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { ProfileData, UserModel, UserModel1, UserModel2, UserModel3 } from '../models/user.model';
import { AuthModel, AuthModel1, AuthModel2, AuthModel3 } from '../models/auth.model';
//import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared.service';
import { TabserviceService } from 'src/app/_fake/services/tabservice.service';



//export type UserType = UserModel | undefined;
export type UserType = UserModel1 | undefined;
export type UserTypePrime = UserModel2 | undefined;
export type UserTypeSubscriber = UserModel3 | undefined;
export type Profile = ProfileData | undefined;


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*'
  })
};


@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  USERDATA_KEY: string='authData';
  ID:any;
  passkey:any;
  token:any;
  expiresin:any;
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
   private authLocalStorageToken = `${environment.appVersion}-${this.USERDATA_KEY}`;
  //private authLocalStorageToken :any;

  currentProfile$: Observable<Profile>;
  currentProfileSubject: BehaviorSubject<Profile>;
  isLoadingProfile$: Observable<boolean>;
  isLoadingSubjectProfile: BehaviorSubject<boolean>;
  // public fields 
   currentUser$: Observable<UserType>;
  currentPrimeUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
   currentUserSubject: BehaviorSubject<UserType>;
  currentUserPrimeSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;



  /////////prime Admin////////////////
  currentUserPrime$: Observable<UserTypePrime>;
  currentPrimeUserPrime$: Observable<UserTypePrime>;
  isLoadingPrime$: Observable<boolean>;
   currentUserSubjectPrime: BehaviorSubject<UserTypePrime>;
  currentUserPrimeSubjectPrime: BehaviorSubject<UserTypePrime>;
  isLoadingSubjectPrime: BehaviorSubject<boolean>;

  ///////////////Subscriber/////////////////
  currentUserSubscribe$: Observable<UserTypeSubscriber>;
  currentPrimeUserSubscribe$: Observable<UserTypeSubscriber>;
  isLoadingSubscribe$: Observable<boolean>;
   currentUserSubjectSubscribe: BehaviorSubject<UserTypeSubscriber>;
  currentUserPrimeSubjectSubscribe: BehaviorSubject<UserTypeSubscriber>;
  isLoadingSubjectSubscribe: BehaviorSubject<boolean>;

  get currentProfileValue(): Profile{
    return this.currentProfileSubject.value;
  }

  set currentProfileValue(user: Profile) {
    this.currentProfileSubject.next(user);
  }

 
//////////////Developer///////////
  get currentUserValue(): UserType{
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }
  /////////////Prime Admin///////////////
  get currentUserValuePrime(): UserTypePrime{
    return this.currentUserSubjectPrime.value;
  }

  set currentUserValuePrime(user: UserTypePrime) {
    this.currentUserSubjectPrime.next(user);
  }
  ////////////Subscribers////////////
  get currentUserValueSubscribe(): UserTypeSubscriber{
    return this.currentUserSubjectSubscribe.value;
  }

  set currentUserValueSubscribe(user: UserTypeSubscriber) {
    this.currentUserSubjectSubscribe.next(user);
  }



  constructor(
    private authHttpService: AuthHTTPService,private httpClient: HttpClient,
    private router: Router,public tabserviceService:TabserviceService,
  ) {
    this.currentProfileSubject = new BehaviorSubject<Profile>(undefined);
    this.isLoadingSubjectProfile= new BehaviorSubject<boolean>(false);
    this.currentProfile$= this.currentProfileSubject.asObservable();
    this.isLoadingProfile$= this.isLoadingSubjectProfile.asObservable();
   

    ////////Developer//////////////
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUserPrimeSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
////////////////////Prime Admin///////////////
this.isLoadingSubjectPrime = new BehaviorSubject<boolean>(false);
    this.currentUserSubjectPrime = new BehaviorSubject<UserTypePrime>(undefined);
    this.currentUserPrimeSubjectPrime = new BehaviorSubject<UserTypePrime>(undefined);
    this.currentUserPrime$ = this.currentUserSubjectPrime.asObservable();
    this.isLoadingPrime$ = this.isLoadingSubjectPrime.asObservable();
    //////////////Subscribers//////////////////////
    this.isLoadingSubjectSubscribe = new BehaviorSubject<boolean>(false);
    this.currentUserSubjectSubscribe = new BehaviorSubject<UserTypeSubscriber>(undefined);
    this.currentUserPrimeSubjectSubscribe = new BehaviorSubject<UserTypeSubscriber>(undefined);
    this.currentUserSubscribe$ = this.currentUserSubjectSubscribe.asObservable();
    this.isLoadingSubscribe$ = this.isLoadingSubjectSubscribe.asObservable();


    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);

    const subscrPrime = this.getUserByTokenPrime().subscribe();
    this.unsubscribe.push(subscrPrime);

    const subscrSubscribe = this.getUserByTokenSubscriber().subscribe();
    this.unsubscribe.push(subscrSubscribe);

    const subscrSubscribeProfile = this.getSubscribeProfileByToken().subscribe();
    this.unsubscribe.push(subscrSubscribeProfile);

    const subscrPrimeProfile = this.getPrimeProfileByToken().subscribe();
    this.unsubscribe.push(subscrPrimeProfile);

    const subscrDevProfile = this.getDevProfileByToken().subscribe();
    this.unsubscribe.push(subscrDevProfile);

   
  }

  
  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
      return this.authHttpService.login(email, password).pipe(
        map((auth: AuthModel1) => {
           debugger
          this.ID = auth.dev.devID;
          this.passkey = auth.passkey;
          this.token = auth.token;
          this.expiresin = auth.expiresin
          const result = this.setAuthFromLocalStorage(auth);
          localStorage.setItem('token', this.token);
          localStorage.setItem('role', 'Developer');
          let time =  new Date(this.expiresin).getTime() 
          localStorage.setItem('token_expiration',time.toString());  // Save expiration timestamp
          //this.tabserviceService.setTabFromLocalStorage()
          console.log('USERDATA_KEY',result)
         
         return result;
         
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    
    
   
    // return this.authHttpService.login(email, password).pipe(
    //   map((auth: AuthModel1) => {
    //      
    //     this.authLocalStorageToken= `${environment.appVersion}-${auth.passkey}`;
    //     const result = this.setAuthFromLocalStoragePrime(auth);
    //     console.log('USERDATA_KEY',result)
      

    //     return result;
       
    //   }),
    //   switchMap(() => this.getUserByToken()),
    //   catchError((err) => {
    //     console.error('err', err);
    //     return of(undefined);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  primelogin(email: string, password: string): Observable<UserTypePrime> {
    this.isLoadingSubject.next(true);
      return this.authHttpService.primeLogin(email, password).pipe(
        map((auth: AuthModel2) => {
           debugger
          this.ID = auth.prime.primeAdminId;
          this.passkey = auth.passkey;
          this.token = auth.token;
          this.expiresin = auth.expiresin
          const result = this.setAuthFromLocalStorage(auth);
          localStorage.setItem('token', this.token);
          localStorage.setItem('role', 'Prime Admin');
          let time =  new Date(this.expiresin).getTime() 
          localStorage.setItem('token_expiration',time.toString());  // Save expiration timestamp
          //this.tabserviceService.setTabFromLocalStorage()
          console.log('USERDATA_KEY',result)
         
         return result;
         
        }),
        switchMap(() => this.getUserByTokenPrime()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
   
  }

  adminlogin(email: string, password: string): Observable<UserTypeSubscriber> {
    this.isLoadingSubject.next(true);
      return this.authHttpService.adminLogin(email, password).pipe(
        map((auth: AuthModel3) => {
           debugger
          this.ID = auth.userDtails.userId;
          this.passkey = auth.passkey;
          this.token = auth.token;
          this.expiresin = auth.expiresin
          const result = this.setAuthFromLocalStorage(auth);
          localStorage.setItem('token', this.token);
          localStorage.setItem('role', 'Subscribers');
          let time =  new Date(this.expiresin).getTime() 
          localStorage.setItem('token_expiration',time.toString());  // Save expiration timestamp
          //this.tabserviceService.setTabFromLocalStorage()
          console.log('USERDATA_KEY',result)
          // this.getUserByTokenSubscriber()
         return result;
         
        }),
        switchMap(() => this.getUserByTokenSubscriber()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
   
  }



  getCode(emailToId: string): Observable<any> {
    return this.authHttpService.getCode(emailToId).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  sendOtp(emailToId: string): Observable<any> {
    return this.authHttpService.otpMail(emailToId).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  logout() {
    
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem('tabData');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }
  private setProfileFromLocalStorage(profile: any): boolean {
      if (profile) {
      localStorage.setItem('profileData', JSON.stringify(profile));
      return true;
    }
    return false;
  }


  getSubscribeProfileByToken(): Observable<Profile> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    debugger
    if (!auth) {
      return of(undefined);
    }
    
    this.isLoadingSubjectProfile.next(true);
    return this.authHttpService.getSubscribeProfileInfo(auth?.userDtails?.userId, auth.passkey,auth.token).pipe(
      map((user: any) => {
        debugger
        console.log('user',user)
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getprofileDataFromLocalStorage();
        this.currentProfileSubject.next(userauth);
        return userauth;
      }),
      finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }

  getPrimeProfileByToken(): Observable<Profile> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    debugger
    if (!auth) {
      return of(undefined);
    }
    
    this.isLoadingSubjectProfile.next(true);
    return this.authHttpService.getPrimeProfileInfo(auth?.prime?.primeAdminId, auth.passkey,auth.token).pipe(
      map((user: any) => {
        debugger
        console.log('user',user)
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getprofileDataFromLocalStorage();
        this.currentProfileSubject.next(userauth);
        return userauth;
      }),
      finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }

  getDevProfileByToken(): Observable<Profile> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    debugger
    if (!auth) {
      return of(undefined);
    }
    
    this.isLoadingSubjectProfile.next(true);
    return this.authHttpService.getDevProfileInfo(auth?.dev?.devID, auth.passkey,auth.token).pipe(
      map((user: any) => {
        debugger
        console.log('user',user)
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getprofileDataFromLocalStorage();
        this.currentProfileSubject.next(userauth);
        return userauth;
      }),
      finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }


  getUserByToken(): Observable<UserType> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    
     debugger
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token,auth?.dev?.devID, auth.passkey).pipe(
      map((user: any) => {
        debugger
        console.log('user',user)
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getAuthDataFromLocalStorage();
        this.currentUserSubject.next(userauth);
        //return userauth;
        let data:any=[];
        data.push({userauth:userauth,user:user})
        return data;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserByTokenPrime(): Observable<UserTypePrime> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    
     debugger
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubjectPrime.next(true);
    return this.authHttpService.getUserByTokenPrime(auth.token,auth?.prime?.primeAdminId, auth.passkey).pipe(
      map((user: any) => {
         debugger
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getAuthDataFromLocalStorage();
        this.currentUserSubjectPrime.next(userauth);
        //return userauth;
        let data:any=[];
        data.push({userauth:userauth,user:user})
        return data;
      }),
      finalize(() => this.isLoadingSubjectPrime.next(false))
    );
  }

  getUserByTokenSubscriber(): Observable<UserTypeSubscriber> {
    // const auth = this.getAuthFromLocalStorage();
    const auth = this.getAuthDataFromLocalStorage();
    
     debugger
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubjectSubscribe.next(true);
   return this.authHttpService.getUserByTokenSubscribe(auth.token,auth?.userDtails?.userId, auth.passkey).pipe(
      map((user: any) => {
        debugger
         console.log('user',user)
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getAuthDataFromLocalStorage();
        this.currentUserSubjectSubscribe.next(userauth);
        //return userauth;
        let data:any=[];
        data.push({userauth:userauth,user:user})
        return data;
      }),
      finalize(() => this.isLoadingSubjectSubscribe.next(false))
    );
    
    // return this.authHttpService.getUserByTokenSubscribe(auth.token,auth?.userDtails?.userId, auth.passkey).pipe(
    //   map((user: any) => {
    //     debugger
    //      console.log('user',user)
    //     if (user == undefined) {
    //       this.logout();
    //     }
    //     const userauth = this.getAuthDataFromLocalStorage();
    //     this.currentUserSubjectSubscribe.next(userauth);
    //     return userauth;
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  public getAuthDataFromLocalStorage(): any | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
   
      if (this.isTokenExpired()) {
        this.logout();
        return;
      }
     
      debugger
      return lsValue ? JSON.parse(lsValue) : null;
      // const authData = JSON.parse(lsValue);
      // return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  
  public getprofileDataFromLocalStorage(): any | undefined {
    try {
      const lsValue = localStorage.getItem('profileData');
   
      debugger
      return lsValue ? JSON.parse(lsValue) : null;
      // const authData = JSON.parse(lsValue);
      // return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  
  isTokenExpired() {
    
    let lsValue = localStorage.getItem(this.authLocalStorageToken);
    if (lsValue) {
      const expiry = (JSON.parse(atob(JSON.parse(lsValue).token.split('.')[1]))).exp;
      return expiry * 1000 < Date.now();
    } else {
      return true;
    }
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(Username: string,NewPassword:string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(Username,NewPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: any): boolean {
     
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      console.log('local',localStorage)
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): any | undefined {
    try {
       
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      // const authData = JSON.parse(lsValue);
      // return authData;
      return lsValue ? JSON.parse(lsValue).token : "";
      
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // private methods
  private setAuthFromLocalStoragePrime(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
     
      return true;
    }
    return false;
  }

  public getAuthFromLocalStoragePrime(): any | undefined {
    try {
       
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  validateEmailCredentials(email: string): Observable<any> {
    return this.authHttpService.validateEmailCredentials(email).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  validateMobileCredentials(mobile: string): Observable<any> {
    return this.authHttpService.validateMobileCredentials(mobile).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  validateUserNameCredentials(userName: string): Observable<any> {
    return this.authHttpService.validateUserNameCredentials(userName).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
 
    

  getDevProfile(MasterID: string,passkey:string,token:string): Observable<Profile> {
    return this.authHttpService.getDevProfileInfo(MasterID,passkey,token).pipe(
      map((result: ProfileData) => {
        const result1 = this.setProfileFromLocalStorage(result);
        debugger
        return result;
      }),
      switchMap(() => this.getDevProfileByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }

  

  getSubscribeProfile(MasterID: string,passkey:string,token:string): Observable<Profile> {
    return this.authHttpService.getSubscribeProfileInfo(MasterID,passkey,token).pipe(
      map((result: ProfileData) => {
        const result1 = this.setProfileFromLocalStorage(result);
        debugger
        return result;
      }),
      switchMap(() => this.getSubscribeProfileByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }

  getPrimeProfile(MasterID: string,passkey:string,token:string): Observable<Profile> {
    return this.authHttpService.getPrimeProfileInfo(MasterID,passkey,token).pipe(
      map((result: ProfileData) => {
        const result1 = this.setProfileFromLocalStorage(result);
        debugger
        return result;
      }),
      switchMap(() => this.getPrimeProfileByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubjectProfile.next(false))
    );
  }



}
