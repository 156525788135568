import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OverviewComponent } from 'src/app/modules/profile/overview/overview.component';
import { ProjectsComponent } from 'src/app/modules/profile/projects/projects.component';
import { Tab } from './user-service';


@Injectable({
  providedIn: 'root'
})
export class TabserviceService implements OnInit {
  public href: string = "";
  constructor(private router : Router) { }
  
   public tabs: Tab[] =[]
   // public tabs: Tab[] = this.getTabFromLocalStorage();
    public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

    ngOnInit(): void {
      let a = this.getTabFromLocalStorage();
      this.tabs.push(a)
      this.href = this.router.url;
      console.log('tabDta',this.tabs)
       debugger
      }

    public removeTab(index: number) {
      debugger
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
    this.tabs[this.tabs.length - 1].active = true;
    }
    this.tabSub.next(this.tabs);
    this.setTabFromLocalStorage(this.tabs)
    }

    public addTab(tab: Tab) {
      debugger
      if(this.tabs.length > 0)
      {
        for (let i = 0; i < this.tabs.length; i++) {
          if (this.tabs[i].active === true) {
          this.tabs[i].active = false;
          }
        
        }
      }

      if(this.tabs.length > 0)
      {
        let a = this.tabs.filter((a:any)=>a.title==tab.title);
        if(a.length > 0)
        {
          debugger
          for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].title == a[0].title) {
            this.tabs[i].active = true;
           this.tabs[i].id= a[0].id;
            this.tabs[i].title= a[0].title;
            this.tabs[i].tabData= a[0].tabData;
            this.tabs[i].link= a[0].link;
            this.tabs[i].component= a[0].component;
            this.tabs[i].icon= a[0].icon;
           this.tabSub.next(this.tabs);
            this.setTabFromLocalStorage(this.tabs)
            // this.tabSub.next(this.tabs);
            // this.setTabFromLocalStorage(this.tabs)
            
            }
          
          }
         
        }
        else
        {
          
          debugger
          tab.id = this.tabs.length + 1;
          tab.active = true;
          this.tabs.push(tab);
          this.tabSub.next(this.tabs);
          this.setTabFromLocalStorage(this.tabs)
        }
      }
      else{
      debugger
      tab.id = this.tabs.length + 1;
      tab.active = true;
      this.tabs.push(tab);
      this.tabSub.next(this.tabs);
      this.setTabFromLocalStorage(this.tabs)
      }   
}

public setTabFromLocalStorage(tab:Tab[]): boolean {
  if (this.tabs ) {
   
    debugger
    localStorage.setItem('tabData', JSON.stringify(tab));
    //localStorage.setItem('currentUrl', this.href);
     
    console.log('local',localStorage)
    return true;
  }
  return false;
}

public getTabFromLocalStorage(): any | undefined {
  try {
   
    debugger
     
    const lsValue = localStorage.getItem('tabData');
    if (!lsValue) {
      return undefined;
    }

    return lsValue ? JSON.parse(lsValue) : "";
    
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
public setTabToggleFromLocalStorage(tabToggle:boolean): boolean {
  if (this.tabs ) {
   
    debugger
    localStorage.setItem('isChecked', JSON.stringify(tabToggle));
    console.log('local',localStorage)
    return true;
  }
  return false;
}

public getTabToggleFromLocalStorage(): any | undefined {
  try {
    
    const lsValue = localStorage.getItem('isChecked');
    if (!lsValue) {
      return undefined;
    }

    return lsValue ? JSON.parse(lsValue) : "";
    
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
}
