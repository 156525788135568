export class AuthModel {
  authToken: string;
  refreshToken: string;
  expiresIn: Date;

  setAuth(auth: AuthModel) {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}

export class AuthModel1 {
  token: string;
  passkey: string;
  success: string;
  expiresin:string;
  dev:any

  setAuth(auth: AuthModel1) {
    this.token = auth.token;
    this.passkey = auth.passkey;
    this.success = auth.success;
    this.dev = auth.dev;
  }
}

export class AuthModel3 {
  token: string;
  passkey: string;
  success: string;
  expiresin:string;
  userDtails:any

  setAuth(auth: AuthModel3) {
    this.token = auth.token;
    this.passkey = auth.passkey;
    this.success = auth.success;
    this.userDtails = auth.userDtails;
  }
}

export class AuthModel2 {
  token: string;
  passkey: string;
  success: string;
  expiresin:string;
  prime:any

  setAuth(auth: AuthModel2) {
    this.token = auth.token;
    this.passkey = auth.passkey;
    this.success = auth.success;
    this.prime = auth.prime;
  }
}



export class otpMail{
  EmailToId: string;
  EmailTime: Date;
}

export class validateCredentials{
  companyId:string;
    EmailID: string;
    MobileNo: string
    Username:string
}

